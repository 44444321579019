<template>
    <div class="fixed skelton">
        <div class="skelton_wrap">
            <vue-skeleton-loader
                type="rect"
                :rounded="true"
                radius="4"
                :width="76"
                :height="26"
                animation="wave"
                :color="color"
                :wave-color="waveColor"
                class="skelton_live"
            />
            <vue-skeleton-loader
                type="rect"
                :width="392"
                :height="38"
                animation="wave"
                :color="color"
                :wave-color="waveColor"
                class="skelton_spacename"
            />
            <ul class="skeleton_curation_wrap">
                <li>
                    <vue-skeleton-loader
                        type="rect"
                        :rounded="true"
                        radius="12"
                        :width="50"
                        :height="44"
                        animation="wave"
                        class="skeleton_curation_item"
                        :color="color"
                        :wave-color="waveColor"
                    />
                </li>
                <li class="skeleton_curation_item2">
                    <vue-skeleton-loader
                        type="rect"
                        :rounded="true"
                        radius="12"
                        :width="65"
                        :height="44"
                        animation="wave"
                        class="skeleton_curation_item"
                        :color="color"
                        :wave-color="waveColor"
                    />
                </li>
                <li class="skeleton_curation_item3">
                    <vue-skeleton-loader
                        type="rect"
                        :rounded="true"
                        radius="12"
                        :width="50"
                        :height="44"
                        class="skeleton_curation_item"
                        animation="wave"
                        :color="color"
                        :wave-color="waveColor"
                    />
                </li>

                <li class="skeleton_curation_item4">
                    <vue-skeleton-loader
                        type="rect"
                        :rounded="true"
                        radius="12"
                        :width="65"
                        :height="44"
                        animation="wave"
                        :color="color"
                        :wave-color="waveColor"
                        class="skeleton_curation_item"
                    />
                </li>
            </ul>

            <ul class="skelton_player_wrap">
                <li class="skelton_player_left">
                    <vue-skeleton-loader
                        type="rect"
                        :rounded="true"
                        radius="16"
                        :width="902"
                        :height="597"
                        animation="wave"
                        class="skelton_player_left_item"
                        :color="color"
                        :wave-color="waveColor"
                    />
                </li>
                <li class="skelton_player_right">
                    <vue-skeleton-loader
                        type="rect"
                        :rounded="true"
                        radius="16"
                        :width="507"
                        :height="597"
                        animation="wave"
                        class="skelton_player_right_item"
                        :color="color"
                        :wave-color="waveColor"
                    />
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
export default {
  name: 'SkeletonLoading',
  data () {
    return {
      waveColor: 'rgba(89, 89, 89, 1)',
      color: '#3B3B3B'
    };
  }
};
</script>
<style src="@/assets/css/player/skeletonloading.css" scoped></style>
